import React,{useState, useEffect} from 'react'
import { navigate } from "gatsby"
import styles from './ScheduleMeeting.module.css'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import 'rc-time-picker/assets/index.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaExclamationCircle } from 'react-icons/fa';
import { Fragment } from 'react';
import { FormText, ModalBody } from 'react-bootstrap';
import { verifyStudent } from '../../../api/api'

const ScheduleMeeting = ({show, setShowMeetingSchedule}) => {
    const [badVerification, setBadVerification] = useState(false);
    const [studentName, setStudentName] = useState("");
    const [studentPhone, setStudentPhone] = useState("");

    const handleClose = () => {
        setShowMeetingSchedule(false);
    }

    useEffect(() => {
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);

    const handleScheduling = (event) => { 
        //Check if name and phone number match db and direct to calendly
        event.preventDefault();
            
        let data = {
          studentName: studentName,
          studentPhone: studentPhone
        };

        console.log(data);
        
        verifyStudent(data)
            .then(res => { 
                console.log(res)
                navigate("http://calendly.com/amunibeck")
            }).catch(function (err) {
                if(err.response.data.status == 401){
                    setBadVerification(true);
                }

    })};

    return(
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='dialog'
        >
            <Modal.Header closeButton>
                <Modal.Title> Schedule a Meeting </Modal.Title>
            </Modal.Header>
                    <>
                        <Form onSubmit={(e) => handleScheduling(e)}>
                            <Modal.Body>
                                    <div>
                                        <h2 style={{textAlign:'center', fontSize:20}}><b style={{color:'black'}}>Only students</b> that have taken <b style={{color:'black'}}>both diagnostic tests</b> will be allowed to schedule a meeting with Amuni.</h2>
                                    </div>
                                    <div class='checkStudent' >
                                        <h3>Student Name:</h3>
                                        <input style={{width:'75%', marginBottom:'10px', marginLeft:'12.5%'}} placeholder='Full Name' onChange={(e) => setStudentName(e.target.value)}></input>
                                        <h3>Parent Phone Number:</h3>
                                        <input style={{width:'75%', marginBottom:'10px', marginLeft:'12.5%'}} placeholder='Phone Number' onChange={(f) => setStudentPhone(f.target.value)}></input>
                                    </div>
                            </Modal.Body>
                            {badVerification ? <p style={{color: 'red', marginLeft: 15}}> <FaExclamationCircle className={styles.loginError}/> Information is incorrect or Student has not received a score report message </p>: null }
                            <Modal.Footer>
                                <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button className={styles.subButton} type='submit'>
                                    Schedule
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
            </Modal>
    )
}

export default ScheduleMeeting