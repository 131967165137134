import React from 'react';
import "../styles/global.css";
import Navbar from '../components/website_components/Navbar/Navbar';
import Footer from '../components/website_components/Footer/Footer';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEO';

const NoHeroLayout = ({ children, seo }) => (
  <>
    <SEO {...seo} />
    <Navbar />
      {children}
    <Footer />
  </>
);

export default NoHeroLayout;







