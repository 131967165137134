import React from 'react'
import "../styles/global.css";

import NoHeroLayout from "../layouts/NoHeroLayout"
import 'animate.css/animate.css' 
import ModalCalendar from '../components/calendar_components/ModalCalendar/ModalCalendar';

const CalendarPage = () => {
    
    return(
        <NoHeroLayout seo={{ title: "Calendar", url: "https://beckcollegeprep.com/calendar", description: "View and register for upcoming events on our calendar such as diagnostics, courses, sessions, and bootcamps." }}>
            <div className={`d-flex justify-content-center`}>
                <ModalCalendar/>
            </div>
        </NoHeroLayout>
    )
}

export default CalendarPage