import React, {useState, useEffect, useRef} from 'react';
import styles from './ModalCalendar.module.css'
import bigB from '../../../images/calendarB.png'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './calendar.css'

import DiagEventModal from '../DiagEventModal/DiagEventModal'
import BootcampEventModal from '../BootcampEventModal/BootcampEventModal'
import GeneralDiagEventModal from '../DiagEventModal/GeneralDiagEventModal'
import GeneralBootcampEventModal from '../BootcampEventModal/GeneralBootcampEventModal'
import SessionEventModal from '../SessionEventModal/SessionEventModal'
// import BiWeekView from './BiWeekView'
import Button from 'react-bootstrap/Button'
import ScheduleMeeting from './ScheduleMeeting';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Form from 'react-bootstrap/Form'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { getDiagnostics, 
    // getSessions, 
    // getAdvisingTypes, 
    getGeneralEvents, 
    getCourses } from '../../../api/api'

const VERTICAL = 620;
const VERTICAL_FILTER = 1050;
const EVENT_MONTH_RANGE = -12
const localizer = momentLocalizer(moment) //Defines localizer for the calendar

// Component
const ModalCalendar = () => {
    // Dummy dates to initialize event modals
    const startDate = new Date(1990, 1, 1, 1, 1)
    const endDate = new Date(1990, 1, 1, 1, 1)

    // diagnostic states
    const [diagnostics, setDiagnostics] = useState([]); // list of diagnostics
    const [showDiag, setShowDiag] = useState(false); // show diagnostic request modal
    const [diagModalInfo, setDiagModal] = useState({start: startDate, end: endDate}); // diag modal info
    const [showGeneralDiag, setShowGeneralDiag] = useState(false); // show diagnostic request modal

    // session states
    const [types, setTypes] = useState([]);
    const [sessions, setSessions] = useState([]); // list of all sessions, to be sorted later
    const [showSession, setShowSession] = useState(false); // show session modal
    const [sessionModalInfo, setSessionModal] = useState({start: startDate, end: endDate}); // session modal info
    
    // general events states
    const [generalEvents, setGeneralEvents] = useState([]);

    // Bootcamps
    const [allBootcamps, setAllBootcamps] = useState([]);
    const [showBootcamp, setShowBootcamp] = useState(false);
    const [bootcampInfo, setBootcampInfo] = useState({});
    const [showBootcampGeneral, setShowBootcampGeneral] = useState(false);
    const [showMeetingSchedule, setShowMeetingSchedule] = useState(false);


    // calendar states
    const [filters, setFilters] = useState({}); // state holding the flags for filtering
    const [myEventsList, setEvents] = useState([]); // list of events
    const [isVertical, setVertical] = useState(false);
    const [isVerticalFilter, setVerticalFilter] = useState(false);

    useEffect(() => {
        getAllEvents();

        // Initialize display mode (vertical)
        setDisplayVertical();

        window.addEventListener('resize', setDisplayVertical, { passive: true })

        // This is only used when the components is unmounted
        return function cleanup() {
            window.removeEventListener('resize', setDisplayVertical, { passive: true })
        };
    }, []);

    useEffect(() => {
        console.log(sessions, diagnostics);
        if(Object.entries(filters).length > 0)
            populateEvents();
    }, [filters, sessions, diagnostics]);

    function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }

        if(window.innerWidth <= VERTICAL_FILTER) {
            setVerticalFilter(true);
        }
        else {
            setVerticalFilter(false);
        }
    }

    //Function to conditionally render the event color based on the type of event
    const eventStyleGetter = (event, start, end, isSelected) => {
        var borderColor;
        var color;
        var backgroundColor;
        if(event.type === 'diagnostic') {
            borderColor = '#DC3F3A'
            color = '#DC3F3A'
        }
        else if(event.type === 'tutoring'){
            borderColor = '#0313A0'
            color = '#0313A0'
        }
        else if(event.type === 'essay'){
            borderColor = '#D30606'
            color = '#D30606'
        }
        else if(event.type === 'general'){
            borderColor = '#4b4b4b'
            color = '#4b4b4b'
        }
        else if(event.type === 'psatbootcamp' || event.type === 'satbootcamp'){
            borderColor = '#0313A0'
            color = '#0313A0'
        }
        else if(event.type === 'actbootcamp'){
            borderColor = '#f07400'
            color = '#f07400'
        }

        
        if(isVertical)
        {
            if(event.type === 'diagnostic') {
                backgroundColor = '#DC3F3A'
                color = '#DC3F3A'
            }
            else if(event.type === 'tutoring'){
                backgroundColor = '#0313A0'
            }
            else if(event.type === 'essay'){
                backgroundColor = '#D30606'
            }
            else if(event.type === 'general'){
                backgroundColor = '#4b4b4b'
            }
            else if(event.type === 'psatbootcamp' || event.type === 'satbootcamp'){
                backgroundColor = '#0313A0'
            }
            else if(event.type === 'actbootcamp'){
                backgroundColor = '#f07400'
            }
            var style = {
                borderColor: borderColor,
                color: color,
                backgroundColor: backgroundColor
            };

        }
        else {
            var style = {
                borderColor: borderColor,
                color: color
            };
        }

        return {
            className: event.type,
            style: style
        };
    }

    // Initialize all the events for the calendar
    function getAllEvents() {
        let newFilters = {...filters};

        getGeneralEvents().then(generalRes => {
            if(generalRes.data?.length > 0) setGeneralEvents(generalRes.data)
            else setGeneralEvents([])
            newFilters['General Events'] = true;

            //Initialize diagnostics
            getDiagnostics().then(diagRes => {
            
                if(diagRes.data?.length > 0) setDiagnostics(diagRes.data)
                else setDiagnostics([])
                newFilters.Diagnostics = true;


                getCourses().then(courseRes => {
                    console.log(courseRes.data)

                    if(courseRes.data.length > 0) setAllBootcamps(courseRes.data)
                    else setAllBootcamps([])
                    newFilters["SAT Bootcamps"] = true;
                    newFilters["ACT Bootcamps"] = true;
                    
                    setFilters(newFilters);
                })
               
            })
        })
    }

    const populateEvents = () => {
        let events = [];
        for(const [key, value] of Object.entries(filters)) {
            
            //Filter for diagnostics
            if(key === "Diagnostics" && value ){
                let diags = diagnostics.map((d, i) => {

                    if(moment().diff(moment(d.date.start), 'hours') < -20 
                        && moment().diff(moment(d.date.start), 'months') > EVENT_MONTH_RANGE
                    )

                    var approvedStudentsNum = d.studentRequests.filter((item) => (item.approved && !item.hidden)).length
                    if(d.enrollmentMax && approvedStudentsNum < d.enrollmentMax)
                        return {
                            id: d._id,
                            title: d.name,
                            desc: d.description,
                            type: 'diagnostic',
                            start: new Date(d.date.start),
                            end: new Date(d.date.end)
                        }
                })
                events = [...diags, ...events];
            }
            if(key === "General Events" && value ){
                let generals = generalEvents.map((g, i) => {
                    if(moment().diff(moment(g.date.start), 'hours') < -12 && moment().diff(moment(g.date.start), 'months') > EVENT_MONTH_RANGE)
                    return {
                        id: g._id,
                        title: g.name,
                        type: 'general',
                        start: new Date(g.date.start),
                        end: new Date(g.date.end)
                    }
                })
                events = [...generals, ...events];
            }
            
                let bootcamps = allBootcamps.map((bootcamp, i) => {
                    let meetings = [];
                    if(bootcamp.enrolledStudents.slice().filter(item => (!item.hidden)).length < bootcamp.enrollmentMax) {
                        meetings = bootcamp.meetings.map((meeting, j) => {
                            if(
                                (meeting.entryType === 'psatbootcamp')
                                && key === "SAT Bootcamps" 
                                && value 
                                && moment().diff(moment(meeting.start), 'hours') < 120
                                && moment().diff(moment(meeting.start), 'months') > EVENT_MONTH_RANGE
                            )
                            {
                                return {
                                    id: bootcamp._id,
                                    info: bootcamp,
                                    title: bootcamp.name + ' Day ' + (j + 1),
                                    type: 'psatbootcamp',
                                    start: new Date(meeting.start),
                                    end: new Date(meeting.end)
    
                                }
                            }
                            else if(
                                meeting.entryType === 'actbootcamp' 
                                && key === "ACT Bootcamps" 
                                && value 
                                && moment().diff(moment(meeting.start), 'hours') < 120
                                && moment().diff(moment(meeting.start), 'months') > EVENT_MONTH_RANGE
                            )
                            {
                                return {
                                    id: bootcamp._id,
                                    info: bootcamp,
                                    title: bootcamp.name  + ' Day ' + (j + 1),
                                    type: 'actbootcamp',
                                    start: new Date(meeting.start),
                                    end: new Date(meeting.end)
                                }
                            }
                            else if(
                                meeting.entryType === 'satbootcamp' 
                                && key === "SAT Bootcamps" 
                                && value 
                                && moment().diff(moment(meeting.start), 'hours') < 120
                                && moment().diff(moment(meeting.start), 'months') > EVENT_MONTH_RANGE
                            )
                            {
                                return {
                                    id: bootcamp._id,
                                    info: bootcamp,
                                    title: bootcamp.name  + ' Day ' + (j + 1),
                                    type: 'satbootcamp',
                                    start: new Date(meeting.start),
                                    end: new Date(meeting.end)
                                }
                            }
                        })
                    }
                    events = [...meetings, ...events];
                })
                
            
            let s = sessions.map((session) => {
                let type = types.find(t => t._id === session.advisingType);
                if(session.availableTimes.length > 0){
                    console.log(type.name, key, value)
                    if(type.name === "Subject Tutoring" && key === "Subject Tutoring" && value) {
                        return {
                            id: session._id,
                            title: type.name,
                            desc: type.description,
                            type: 'tutoring', 
                            slots: session.availableTimes,
                            start: new Date(session.date),
                            end: new Date(session.date)
                        }
                    }
                }
            })
            events = [...s, ...events]
        }
        setEvents(events)
    }

    const handleShow = (event) => {
        if(event.type === 'diagnostic'){
        setDiagModal(event);
        setShowDiag(true);
        }
        else if(event.type === 'psatbootcamp' || event.type === 'actbootcamp' || event.type === 'satbootcamp') {
            setShowBootcamp(true);
            setBootcampInfo(event.info);
        }
        else if(event.type === 'tutoring') {
            setSessionModal(event);
            setShowSession(true);
        }
    }

    const handleOffRange = (event) => {
        console.log(document.styleSheets)
        if(event === 'week') {
            let style = document.createElement("style");
            style.setAttribute('id', 'calendar-weekview-style');
            document.head.appendChild(style);
            style.sheet.insertRule(".rbc-off-range-bg {background: none; opacity: 1;}", 0);
            style.sheet.insertRule(".rbc-off-range {color: #212529;}", 0);
            style.sheet.addRule(".rbc-off-range-bg", 'background: none;', 0);
            style.sheet.addRule(".rbc-off-range", "color: #212529;", 0);
        }
        else {
            if(document.styleSheets[document.styleSheets.length-1].href === null){
                var element = document.getElementById('calendar-weekview-style');
                element.parentNode.removeChild(element);
            }
        }
    }
    
    // method to update filterList
    const handleFilter = (e, key) => {
        let f = {...filters};
        f[key] = e.target.checked;
        setFilters(f);
    }
    
    const renderFilters = () => {
        let rendered = [];
        let i = 1;
        for(const key in filters) {
            
            rendered.push(
                <Form.Check 
                    key={key} 
                    className='filter-switch' 
                    label={key} type='switch' 
                    id={`check-${i}`} 
                    onChange={(event) => handleFilter(event, key)} 
                    checked={filters[key]}
                />
            )
            i++;
        }
        return rendered;
    }

    // sub-component for the filter dialogue
    const FilterPopover = (props) => (
        <Popover className={`${styles.filterTooltip} filter-tooltip`} id="popover-filter" {...props}>
            <Popover.Content>
                {renderFilters()}
            </Popover.Content>
        </Popover>
    )

    return (
        <>
        <MyCalendar 
            isVerticalFilter={isVerticalFilter} 
            filters={filters} 
            renderFilters={renderFilters} 
            myEventsList={myEventsList} 
            eventStyleGetter={eventStyleGetter} 
            handleShow={handleShow} 
            handleOffRange={handleOffRange}
            setShowGeneralDiag={setShowGeneralDiag}
            setShowBootcampGeneral={setShowBootcampGeneral}
            setShowMeetingSchedule={setShowMeetingSchedule}
        />
        <GeneralDiagEventModal  show={showGeneralDiag} setShowDiag={setShowGeneralDiag} diagModalInfo={diagModalInfo} diagnostics={diagnostics}></GeneralDiagEventModal>
        <DiagEventModal  show={showDiag} setShowDiag={setShowDiag} diagModalInfo={diagModalInfo} diagnostics= {diagnostics}></DiagEventModal>
        <SessionEventModal  show={showSession} setShowSession={setShowSession} sessionModalInfo={sessionModalInfo}></SessionEventModal>
        <BootcampEventModal show={showBootcamp} setShowBootcamp={setShowBootcamp} bootcampInfo={bootcampInfo} allBootcamps={allBootcamps}/>
        <GeneralBootcampEventModal show={showBootcampGeneral} setShowBootcamp={setShowBootcampGeneral} bootcampInfo={bootcampInfo} allBootcamps={allBootcamps}/>
        <ScheduleMeeting  show={showMeetingSchedule} setShowMeetingSchedule={setShowMeetingSchedule}></ScheduleMeeting>
        </>
    )
}

export default ModalCalendar



// sub-component for the react calendar (For whatever reason, this sub-component needs to be outside of ModalCalendar because of rerendering issues)
const MyCalendar = ({isVerticalFilter, filters, renderFilters, myEventsList, eventStyleGetter, handleShow, handleOffRange, setShowGeneralDiag, setShowBootcampGeneral, setShowMeetingSchedule}) => {
    let formats = {
        weekdayFormat: 'dddd'
    }
    
    // sub-component for the filter dialogue
    const popover = (
        <Popover className={`${styles.filterTooltip} filter-tooltip`} id="popover-filter">
            <Popover.Content>
                {renderFilters()}
            </Popover.Content>
        </Popover>
    )

    return(
        <>
        {!isVerticalFilter ?
            <>
             
            <div className={styles.calendarBox}>
                <div className={`${styles.bigB} d-flex justify-content-center align-items-center`} style={{position:'absolute'}}>
                    <img src={bigB} alt="Beck's Big B"  style={{height:'70%'}}></img>
                </div>
                <Calendar
                    className='actual-calendar'
                    localizer={localizer}
                    formats={formats}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    popup = {true}
                    eventPropGetter = {eventStyleGetter}
                    onSelectEvent = {(event, e) => {handleShow(event)}}
                    onView = {(event) => {handleOffRange(event)}}
                    views={{
                        month: true,
                        // week: BiWeekView,
                    }}
                />
            </div>
            <div className={styles.filterBox}>
                <div className={styles.filterCard}>
                    <h3 style={{marginBottom: '30px'}}>Filters:</h3>
                    <div>
                        {Object.keys(filters).length > 0 ?
                            <Form className='d-flex flex-column align-items-start' >
                                {renderFilters()}
                            </Form>
                        : 
                            <p>No events found</p>
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-start' style={{position: 'relative', left: '23%', maxWidth: '185px'}}>
                    <Button className={styles.diagBtn} onClick={setShowGeneralDiag}>Schedule a Diagnostic</Button>
                </div>
                <div className='d-flex justify-content-start' style={{marginTop: '20px', position: 'relative', left: '23%', maxWidth: '185px'}}>
                    <Button className={styles.courseBtn} onClick={setShowBootcampGeneral}>Register for a Bootcamp</Button>
                </div>
                <div className='d-flex justify-content-start' style={{marginTop: '20px', position: 'relative', left: '23%', maxWidth: '185px'}}>
                    <Button style={{marginBottom: '20px'}} className={styles.meetingBtn} onClick={setShowMeetingSchedule}>Schedule a Meeting</Button>
                </div>
            </div>
            </>
        :
            <>
            <div className='d-flex justify-content-center flex-wrap' style={{width: '100%'}}>
                <div className={styles.calendarBox}>
                    <div className={`${styles.filters} d-flex justify-content-end`}>
                        
                        
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <Button style={{marginBottom: '20px'}} className={styles.diagBtn} onClick={setShowGeneralDiag}>Schedule a Diagnostic</Button>
                        <Button style={{marginBottom: '20px'}} className={styles.courseBtn} onClick={setShowBootcampGeneral}>Register for a Bootcamp</Button>
                        <Button style={{marginBottom: '20px'}} className={styles.meetingBtn} onClick={setShowMeetingSchedule}>Schedule a Meeting</Button>
                    </div>
                    <div className={`${styles.bigB} d-flex justify-content-center align-items-center`} style={{position:'absolute'}}>
                        <img src={bigB} alt="Beck's Big B"  style={{height:'55%'}}></img>
                    </div>
                   
                    <Calendar
                        className='actual-calendar'
                        localizer={localizer}
                        events={myEventsList}
                        startAccessor="start"
                        endAccessor="end"
                        popup = {true}
                        eventPropGetter = {eventStyleGetter}
                        onSelectEvent = {(event, e) => {handleShow(event)}}
                        onView = {(event) => {handleOffRange(event)}}
                        views={{
                            month: true,
                            // week: BiWeekView,
                        }}
                    />
                </div>
                <div className={`${styles.filterBoxVertical}`}>
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover}>
                            <Button className={styles.filterBtn}>Filters</Button>
                    </OverlayTrigger>
                </div>
            </div>
            </>
        }
        </>
    )
}




