import React,{useState, useEffect} from 'react'
import styles from './DiagEventModal.module.css'
import './DiagEventModal.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';
import moment from 'moment'
import {doubleDiagnosticRequest, newCurrentDiagnosticRequest, getSchools} from '../../../api/api'
import { scryRenderedComponentsWithType } from 'react-dom/test-utils'
import {isValidName} from '../../../utility/general'

const BLANK_ERRORS =  ['', '', '', '', '', '', '', '', '',''];
const VERTICAL = 750;

// Function to traverse through the array of errors, and passing the appropriate message into the errors state
const initializeFlags = (errors, setErrors) => {
    let newErrors = BLANK_ERRORS.slice();
    if(errors)
    {
      for(var i = 0; i < errors.length; i++)
      {
        if(errors[i].param === "studentName")
        {
          newErrors[0] = errors[i].msg;
        }
        else if(errors[i].param === "studentPhone")
        {
          if(newErrors[1].length === 0)
          {
            newErrors[1] = errors[i].msg
          }
        }
        else if(errors[i].param === "gradeLevel")
        {
          newErrors[2] = errors[i].msg
        }
        else if(errors[i].param === "school")
        {
          newErrors[3] = errors[i].msg
        }
        else if(errors[i].param === "parentName")
        {
          newErrors[4] = errors[i].msg
        }
        else if(errors[i].param === "parentEmail")
        {
          if(newErrors[5].length === 0)
          {
            newErrors[5] = errors[i].msg
          }
        }
        else if(errors[i].param === "parentPhone")
        {
          if(newErrors[6].length === 0)
          {
            newErrors[6] = errors[i].msg
          }
        }
        else if(errors[i].param === "mathClass")
        {
            newErrors[8] = errors[i].msg
        }
        else if(errors[i].param === "extendedTime")
        {
            newErrors[7] = errors[i].msg
        }
        else if(errors[i].param === "location")
        {
            newErrors[9] = errors[i].msg
        }
      }
    }
    setErrors(newErrors);
}
  

// Component
const DiagEventModal = ({show, setShowDiag, diagModalInfo, diagnostics}) => {
    const [errors, setErrors] = useState(BLANK_ERRORS); //list of errors
    const [submitted, setSubmit] = useState(false); //request status
    const [studentType, setStudentType] = useState(null);
    const [type, setType] = useState(null);
    const [isVertical, setVertical] = useState(false);
    const [diagnosticTwo, setSecond] = useState(null);
    const [twoSelected, setTwoSelected] = useState(false);
    const [extended, setExtended] = useState('');
    const [location, setLocation] = useState('');
    const [selectedSchool, setSchool] = useState('');

    const [mathLevel, setMathLevel] = useState('');
    const [loading, setLoading] = useState(false);
    const [schoolList, setSchoolList] = useState([]);

    useEffect(() => {
      // Initialize display mode (vertical)
      setDisplayVertical();

        getSchools()
        .then(res => {
            let list = res.data.map(({name}) => name)
            console.log(list)
            setSchoolList(list)
        })
        .catch(err => console.log(err))

      window.addEventListener('resize', setDisplayVertical, { passive: true })

      // This is only used when the components is unmounted
      return function cleanup() {
          window.removeEventListener('resize', setDisplayVertical, { passive: true })
      };
    }, [])

    useEffect(() => {
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);

    // Reset errors when a request is submitted, or new diagModalInfo is opened
    useEffect(() => {
        setErrors(BLANK_ERRORS);
    }, [submitted, show]);

    useEffect(() => {
        if(type && diagnosticTwo)
            setTwoSelected(true);
    }, [type, diagnosticTwo]);
   
    useEffect(()=> {
        setSecond(null);
        setTwoSelected(false);
        setSecond(null);
        setSubmit(false);
        setSchool('');
        setMathLevel('');
    }, [studentType])
    
    // function to send a student request to the database
    const handleRegister = () => {
        setLoading(true);
        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value;
        let parentEmail = document.getElementById('parentemail').value;
   
        
        isValidName(name)
        .then(res => {
            if(res) {
                if(studentType==='new') {
                    let grade = document.getElementById('grade').value;
                    let school
                    (selectedSchool === 'other'
                    ? school = document.getElementById('schoolInput').value
                    : school = selectedSchool
                    )
                   
                    let parentName = document.getElementById('parentname').value;
                    let parentPhone = document.getElementById('parentphone').value;
                    let prevScores = document.getElementById('prevscores').value;
        
                    let requestObject;
                    if(type === 'SAT') {
                        requestObject = {
                            satId: diagModalInfo.id,
                            actId: diagnosticTwo,
                            studentName: name, 
                            studentPhone: phone, 
                            gradeLevel: grade, 
                            school: school, 
                            parentName: parentName, 
                            parentEmail: parentEmail, 
                            parentPhone: parentPhone, 
                            mathClass: mathLevel,
                            prevScores: prevScores,
                            extendedTime: extended,
                            location: location,

                        };
                    }
                    else{
                        requestObject = {
                            satId: diagnosticTwo,
                            actId: diagModalInfo.id,
                            studentName: name, 
                            studentPhone: phone, 
                            gradeLevel: grade, 
                            school: school, 
                            parentName: parentName, 
                            parentEmail: parentEmail, 
                            parentPhone: parentPhone, 
                            mathClass: mathLevel,
                            prevScores: prevScores,
                            extendedTime: extended,
                            location: location,
                        };
                    }
                    
                    doubleDiagnosticRequest(requestObject)
                    .then(res1 => {
                        setLoading(false);
                        console.log(res1); 
                        setSubmit(true);
                    })
                    .catch(function (err) {
                        console.log(err);
                        if(err.response?.data.status === 500){
                            alert(err.response.data.message)
                        }
                        else {
                            initializeFlags(err.response.data.data, setErrors)
                        }
                        setLoading(false);
                    });
                }
                else{
                    let requestObject = {
                        testType: type,
                        studentName: name, 
                        studentPhone: phone,
                        parentName: null, 
                        parentEmail: parentEmail, 
                        parentPhone: null,  
                        extendedTime: extended,
                        location: location,
                    };
        
                    newCurrentDiagnosticRequest(diagModalInfo.id, requestObject)
                    .then(res => {
                        console.log(res); 
                        setLoading(false);
                        setSubmit(true)
                    })
                    .catch(function (err) {
                        console.log(err.response.data.status);
                        if(err.response.data.status === 500){
                            alert(err.response.data.message)
                        }
                        else {
                            initializeFlags(err.response.data.data, setErrors)
                        }
                        setLoading(false);
                    });
                }
            }
            else{
                let newErrors = errors.slice();
                newErrors[0] = "Please provide a first and last name";
                setErrors(newErrors);
                setLoading(false);
            }
        })
        
    }

    const handleClose = () => {
        setShowDiag(false);
        setTimeout(()=> {
            setType(null);
            setSecond(null);
            setTwoSelected(false);
            setSecond(null);
            setSubmit(false);
            setExtended('');
            setLocation('');
            setSchool('')
            setMathLevel('');
            setStudentType(null);
            setLoading(false);
        }, 500);
    }

    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }

    const handleExtended = (e) => {
        setExtended(e.target.value);
    }

    const handleMathLevel = (e) => {
        setMathLevel(e.target.value);
    }

    const handleType = (e) => {
        console.log(e.target.value)
        setType(e.target.value)
    }

    const handleSecond = (e) => {
        console.log(e.target.value)
        setSecond(e.target.value)
    }

    function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='dialog'
        >
            <Modal.Header closeButton>
            <Modal.Title>Register for a Diagnostic</Modal.Title>
            </Modal.Header>
            {submitted 
            ?(
                <><Modal.Body>
                <p style={{fontWeight:'500'}}>Your request has been submitted!</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Okay
                </Button>
                </Modal.Footer></>
            )
            :(
                <><Modal.Body>
                <Form id='requestForm'>
                    <Form.Group>
                        <p style={{marginBottom: '10px', fontSize: '18px'}}>Are you a Potential Beck Student or a Current Beck Student? </p>
                        <div>
                            <Form.Check 
                                type='radio'
                                name='studenttype'
                                value='new'
                                label={`Potential Beck Student`}
                                onChange={() => setStudentType('new')}
                            />
                            <Form.Check 
                                type='radio'
                                name='studenttype'
                                value='current'
                                label={`Current Beck Student`}
                                onChange={() => setStudentType('current')}
                            />
                        </div>
                    </Form.Group>
                    {studentType?
                        <Form.Group style={{marginTop: '10px'}}>
                            <p style={{marginBottom: '10px', fontSize: '18px'}}>Please select a test type for this day: </p>
                            <div>
                                <Form.Check 
                                    type='radio'
                                    name='diagtype'
                                    value='SAT'
                                    label={`SAT`}
                                    onChange={handleType}
                                    inline
                                />
                                <Form.Check 
                                    type='radio'
                                    name='diagtype'
                                    value='ACT'
                                    label={`ACT`}
                                    onChange={handleType}
                                    inline
                                />
                            </div>
                        </Form.Group>
                    :
                        null
                    }
                    
                    
                    {type ?
                    <>
                        {studentType==='new'
                        ? 
                            <>
                            {studentType==='current'
                                ? null
                                : <>
                                <p style={{marginBottom: '5px', fontSize: '14px'}}>To be approved for our COMPLIMENTARY diagnostics, schedule <span style={{fontWeight: '600'}}>both</span> of the following:</p>
                                <p style={{marginBottom: '0px', fontSize: '14px'}}>- an SAT diagnostic exam</p>
                                <p style={{marginBottom: '10px', fontSize: '14px'}}>- an ACT diagnostic exam</p>
                                </>
                            }
                            
                            <p style={{marginBottom: '10px', fontSize: '18px'}}>First Diagnostic: </p>
                            {isVertical ?
                            <p style={{textAlign: 'center', color: 'black'}}>

                                {`(${type})
                                ${diagModalInfo.start.toString().slice(0,4)} ${moment(diagModalInfo.start).format('h:mm A')} - 
                                ${moment(diagModalInfo.end).format('h:mm A')}`}
                            </p>
                            : 
                            <div className={`${styles.selectedDiagInput} d-flex justify-content-around`}>
                                <p style={{color: 'black', fontSize: '16px'}}>Exam: {type}</p>
                                <p style={{color: 'black', fontSize: '16px'}}>Day: {moment(diagModalInfo.start).format('dddd')}</p>
                                <p style={{color: 'black', fontSize: '16px'}}>Date: {diagModalInfo.start.toLocaleDateString()}</p>
                                <p style={{color: 'black', fontSize: '16px'}}>Time: {moment(diagModalInfo.start).format('h:mm A')} - {moment(diagModalInfo.end).format('h:mm A')}</p>
                            </div>
                            }
                            <Form.Group>
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>Second Diagnostic: </p>
                                <div className='d-flex justify-content-center'>
                                <Form.Control 
                                    onChange={(e) => {handleSecond(e)}} 
                                    defaultValue='' 
                                    id={`type`} 
                                    as="select" 
                                    required
                                    style={{width: '75%'}}
                                >
                                    <option value='' disabled>Select Second Diagnostic Date</option>
                                    {diagnostics?.slice().reverse().map((item, i) => {
                                        let start = new Date(item.date.start);
                                        let end = new Date(item.date.end);
                                        if(moment().diff(moment(start), 'hours') < -12 
                                            && moment(diagModalInfo.start).diff(moment(start), 'days') !== 0){
                                            return(
                                            <option value={item._id} key={i}>
                                                {isVertical?
                                                    `${(type === 'SAT' 
                                                    ? `(ACT) `
                                                    : `(SAT) `)}
                                                    
                                                    ${start.toString().slice(0,4)} ${end.toLocaleDateString()} 
                                                    ${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`
                                                :
                                                    `Exam: ${(type === 'SAT' ?`ACT` :`SAT`)}\xa0\xa0 
                                                    Day: ${moment(start).format('dddd')}\xa0\xa0 
                                                    Date: ${end.toLocaleDateString()}\xa0\xa0 
                                                    Time: ${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`
                                                }
                                                
                                            </option>
                                            )
                                        }
                                    })}
                                </Form.Control>
                                </div>
                            </Form.Group>
                        </>
                        : 
                        <>
                            {isVertical ?
                            <p style={{textAlign: 'center', color: 'black'}}>

                                {`(${type})
                                ${diagModalInfo.start.toString().slice(0,4)} ${moment(diagModalInfo.start).format('h:mm A')} - 
                                ${moment(diagModalInfo.end).format('h:mm A')}`}
                            </p>
                            : 
                            <div className={`${styles.selectedDiagInput} d-flex justify-content-around`}>
                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Exam: {type}</p>
                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Day: {moment(diagModalInfo.start).format('dddd')}</p>
                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Date: {diagModalInfo.start.toLocaleDateString()}</p>
                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Time: {moment(diagModalInfo.start).format('h:mm A')} - {moment(diagModalInfo.end).format('h:mm A')}</p>
                            </div>
                            }
                        </>
                        }
                        
                    </>
                    :
                        null
                    }
                    
                    {(twoSelected || (type && studentType==='current')) ? 
                        <>
                        <p style={{marginTop: '30px', marginBottom: '10px', fontSize: '18px'}}>Please fill out the form below: </p>
                        <Form.Group>
                        <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                            {errors[0].length > 0 ? <ErrorTooltip id='name' errorMsg={errors[0]}/> : null}
                            <Form.Control  className = {``} id='name' placeholder="Student Name" required/>
                        </div>
                        </Form.Group>
                        <Form.Group>
                        <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                            {errors[1].length > 0 ? <ErrorTooltip id='phone' errorMsg={errors[1]}/> : null}
                            <Form.Control className = {``} id='phone' placeholder="Student Phone Number" required/>
                        </div>
                        </Form.Group>
                        <Form.Group>
                        <div onFocus={()=>clearError(5)} style={{position: 'relative'}}>
                            {errors[5].length > 0 ? <ErrorTooltip id='parentemail' errorMsg={errors[5]}/> : null}
                            <Form.Control className = {``} id='parentemail' type='email' placeholder="Parent/Guardian Email*" required/>
                        </div>
                        <p style={{fontSize: '14px', marginTop: '3px'}}>*This is the email that will receive notifications related to this diagnostic.</p>
                        </Form.Group>
                        {studentType === 'new' ?
                        <>
                            <Form.Group >
                            <div onFocus={()=>clearError(2)} style={{position: 'relative'}}>
                                {errors[2].length > 0 ? <ErrorTooltip id='grade' errorMsg={errors[2]}/> : null}
                                <Form.Control className = {``} defaultValue='' id='grade' as="select" required>
                                <option value='' disabled>Student Grade Level</option>
                                <option value='9'>9th Grade (Freshman)</option>
                                <option value='10'>10th Grade (Sophomore)</option>
                                <option value='11'>11th Grade (Junior)</option>
                                <option value='12'>12th Grade (Senior)</option>
                                </Form.Control>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(3)} style={{position: 'relative'}}>
                                {errors[3].length > 0 ? <ErrorTooltip id='school' errorMsg={errors[3]}/> : null}
                                <Form.Control className = {``} defaultValue='' onChange={(e) => {setSchool(e.target.value); console.log(e.target.value)}} id='schoolName' as="select" required>
                                    <option value='' disabled>School</option>
                                    {schoolList.map((name) => {
                                        return(
                                            <option key={name} value={name}>{name}</option>
                                        )
                                    })}
                                    <option value='other' >Other...</option>

                                </Form.Control>
                                {selectedSchool === 'other'?
                                    <Form.Control className = {``} id='schoolInput' placeholder="Please Type the School's Name" required/>
                                :   null
                                }
                                
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(4)} style={{position: 'relative'}}>
                                {errors[4].length > 0 ? <ErrorTooltip id='parentname' errorMsg={errors[4]}/> : null}
                                <Form.Control className = {``} id='parentname' placeholder="Parent/Guardian Name" required/>
                            </div>
                            </Form.Group>
                            
                            <Form.Group>
                            <div onFocus={()=>clearError(6)} style={{position: 'relative'}}>
                                {errors[6].length > 0 ? <ErrorTooltip id='parentphone' errorMsg={errors[6]}/> : null}
                                <Form.Control className = {``} id='parentphone' placeholder="Parent/Guardian Phone Number" required/>
                            </div>
                            </Form.Group>
                            <Form.Group style={{marginTop: '20px'}}>
                                <div onFocus={()=>clearError(8)} style={{position: 'relative'}}>
                                    {errors[8].length > 0 ? <ErrorTooltip id='mathclass' errorMsg={errors[8]}/> : null}
                                    <p style={{marginBottom: '10px', fontSize: '18px'}}>What level math class has the student most recently completed? </p>
                                    <div>
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Algebra'}
                                            label={`Algebra`}
                                            onChange={handleMathLevel}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Algebra II'}
                                            label={`Algebra II`}
                                            onChange={handleMathLevel}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Pre-Calc'}
                                            label={`Pre-Calc`}
                                            onChange={handleMathLevel}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Calculus'}
                                            label={`Calculus`}
                                            onChange={handleMathLevel}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Calculus II'}
                                            label={`Calculus II`}
                                            onChange={handleMathLevel}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='math'
                                            value={'Other'}
                                            label={`Other`}
                                            onChange={handleMathLevel}
                                        />
                                    </div>
                                </div>
                                </Form.Group>
                        </>
                        : null
                        }
                        <Form.Group>
                        <div onFocus={()=>clearError(7)} style={{position: 'relative'}}>
                            {errors[7].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[7]}/> : null}
                            <p style={{marginTop: '30px', marginBottom: '10px', fontSize: '18px'}}>Does the student require extended time? </p>
                            <div>
                                <Form.Check 
                                    inline
                                    type='radio'
                                    name='extended'
                                    value={true}
                                    label={`Yes`}
                                    onChange={handleExtended}
                                />
                                <Form.Check 
                                    inline
                                    type='radio'
                                    name='extended'
                                    value={false}
                                    label={`No`}
                                    onChange={handleExtended}
                                />
                            </div>
                        </div>
                        </Form.Group>
                        <Form.Group>
                            <div onFocus={()=>clearError(9)} style={{position: 'relative'}}>
                                {errors[9].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[9]}/> : null}
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>How will the Student be attending? </p>
                                <div>
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'Zoom'}
                                        label={`Zoom`}
                                        onChange={() => setLocation('Zoom')}
                                    />
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'In Person'}
                                        label={`In Person`}
                                        onChange={() => setLocation('In Person')}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                        
                        {studentType === 'new' ? 
                        <>
                            <p style={{marginBottom: '10px', fontSize: '18px',marginTop: '20px'}}>Optional Field: </p>
                            <Form.Group>
                                <Form.Control  className = {``} id='prevscores' as='textarea' rows='3' maxLength='40' placeholder="Previous Test Scores (i.e. SAT: 1000)" required/>
                            </Form.Group>
                        </>
                        : null
                        }
                        </>
                    :   
                        null
                    }
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                    Cancel
                </Button>
                {twoSelected || (type && studentType==='current')? 
                    (loading ?
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            disabled
                            style={{minWidth: '83px'}}
                        >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button 
                            variant="primary" 
                            className={`${styles.subButton} register-diagnostic`}
                            type='submit' 
                            onClick={function () {handleRegister()}}
                        >
                            Register
                        </Button>
                    )
                :
                    <Button 
                    variant="primary" 
                    className={styles.subButton}
                    disabled
                    >
                        Register
                    </Button>
                }
                </Modal.Footer></>
            )
            }
        </Modal>
    )
}

export default DiagEventModal;