import React,{useState, useEffect} from 'react'
import styles from './SessionEventModal.module.css'
import './SessionEventModal.css'
import { Link, navigate } from "gatsby"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

// Component
const SessionEventModal = ({show, setShowSession, sessionModalInfo}) => {
  
  const [timeIds, setID] = useState([]);

  useEffect(() => {
    console.log(timeIds)
  }, [timeIds]);

    useEffect(() => {
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);
   
    const handleClose = () => {
        setID([]);
        setShowSession(false);
    }

    const handleTime = (e) => {
      if(e.target.checked) {
        let list = timeIds.slice();
        console.log(e.target.value)
        list.push(e.target.value);
        setID(list);
      }
      else{
        let list = timeIds.slice();
        list.splice(list.findIndex(item => item === e.target.value),1);
        setID(list);
      }
      
    }

    const handleLink = () => {
      console.log(sessionModalInfo)
      let link = `/checkout/session/${sessionModalInfo.id}`;

      navigate(
        link,
        {
          state: {
            timeIds
          },
        }
      )
    }

    return (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className='dialog'
        >
          <Modal.Header closeButton>
          <Modal.Title>Advising Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{fontWeight:'500'}}>Reserve {sessionModalInfo.title}?</p>
            {sessionModalInfo.type === 'advice' ?
              <p>An advising session with Amuni Beck</p>
            :
              <p>An advising session with Mercy Kim</p>
            }
            <p>Date: {sessionModalInfo.start.toString().slice(0,4)} {sessionModalInfo.start.toLocaleDateString()}</p>
            {/* <Form.Group>
              <Form.Control 
                  onChange={handleSlot} 
                  defaultValue='' 
                  id={`time`} 
                  as="select" 
                  required
              >
                  <option value='' disabled>Select a Time Slot</option>
                  {sessionModalInfo.slots ? 
                    <>
                    {sessionModalInfo.slots.slice().reverse().map((item, i)=>(
                      <option key={`dropdown${i}`} value={item.id}>
                        {item.start.toLocaleString(undefined, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })} 
                         - 
                        {item.end.toLocaleString(undefined, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </option>
                    ))}
                    </>
                  :
                    null
                  }
              </Form.Control>
            </Form.Group> */}
            <Form.Group>
                <p style={{marginBottom: '0px'}}>Time Options: </p>
                {sessionModalInfo.slots ? 
                  <>
                  {sessionModalInfo.slots.map((item, i)=>(
                    <Form.Check className={`${styles.wholeCheck} d-flex align-items-center`} type='checkbox' name='time-slot' key={`dropdown${i}`}>
                      <Form.Check.Input 
                        id={`check${i}`} 
                        type='checkbox' 
                        className={styles.checkSquare} 
                        value={item._id} 
                        onChange={handleTime}
                      />
                      <span className={styles.newCheckbox}></span>
                      <Form.Check.Label htmlFor={`check${i}`} className={styles.checkLabel}>
                        {`${new Date(item.start).toLocaleString(undefined, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                          - 
                        ${new Date(item.end).toLocaleString(undefined, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}`}
                      </Form.Check.Label>
                    </Form.Check>
                  ))}
                  </>
                :
                  null
                }
            </Form.Group>
            {timeIds.length === 0?
                <p>Please select one or more advising timeslot from the list above to register for a session </p>
            :
                <p>Clicking the reserve button below will take you to a checkout page for this advising session.</p>
            }
            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                Cancel
            </Button>
            {timeIds.length === 0?
              
                <Button variant="primary" className={styles.subButton} type='button' disabled>Reserve</Button>
            
            :
                <Button variant="primary" onClick={handleLink} className={styles.subButton} type='button'>Reserve</Button>
            }
            
            </Modal.Footer>
        </Modal>
    )
}

export default SessionEventModal;