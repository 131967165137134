import React,{useState, useEffect} from 'react'
import { Link, navigate } from "gatsby"
import styles from './BootcampEventModal.module.css'
import './BootcampEventModal.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';
import moment from 'moment'
import {newBootcampRequest, validateCourseRequest} from '../../../api/api'
import { scryRenderedComponentsWithType } from 'react-dom/test-utils'
import {isValidName} from '../../../utility/general'

const BLANK_ERRORS =  ['', '', '', '', '', '', '', '', '', '', ''];
const VERTICAL = 750;

// Function to traverse through the array of errors, and passing the appropriate message into the errors state
const initializeFlags = (errors, setErrors) => {
    let newErrors = BLANK_ERRORS.slice();
    if(errors)
    {
      for(var i = 0; i < errors.length; i++)
      {
        if(errors[i].param === "studentName")
        {
          newErrors[0] = errors[i].msg;
        }
        else if(errors[i].param === "studentPhone")
        {
          if(newErrors[1].length === 0)
          {
            newErrors[1] = errors[i].msg
          }
        }
        else if(errors[i].param === "gradeLevel")
        {
          newErrors[2] = errors[i].msg
        }
        else if(errors[i].param === "school")
        {
          newErrors[3] = errors[i].msg
        }
        else if(errors[i].param === "parentName")
        {
          newErrors[4] = errors[i].msg
        }
        else if(errors[i].param === "parentEmail")
        {
          if(newErrors[5].length === 0)
          {
            newErrors[5] = errors[i].msg
          }
        }
        else if(errors[i].param === "parentPhone")
        {
          if(newErrors[6].length === 0)
          {
            newErrors[6] = errors[i].msg
          }
        }
        else if(errors[i].param === "mathClass")
        {
            newErrors[8] = errors[i].msg
        }
        else if(errors[i].param === "location")
        {
            newErrors[10] = errors[i].msg
        }
      }
    }
    setErrors(newErrors);
}
  

// Component
const BootcampEventModal = ({show, setShowBootcamp, bootcampInfo, allBootcamps}) => {
    const [errors, setErrors] = useState(BLANK_ERRORS); //list of errors
    const [submitted, setSubmit] = useState(false); //request status
    const [studentType, setStudentType] = useState(null);
    const [validated, setValidate] = useState(false);
    const [isVertical, setVertical] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);
    const [tutorName, setTutorName] = useState(null);
    const [location, setLocation] = useState('');

    useEffect(() => {
        
      // Initialize display mode (vertical)
      setDisplayVertical();

      window.addEventListener('resize', setDisplayVertical, { passive: true })

      // This is only used when the components is unmounted
      return function cleanup() {
          window.removeEventListener('resize', setDisplayVertical, { passive: true })
      };
    }, [])

    useEffect(() => {
        console.log(bootcampInfo)
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);

    // Reset errors when a request is submitted, or new bootcampInfo is opened
    useEffect(() => {
        setErrors(BLANK_ERRORS);
    }, [submitted, show]);
   
    useEffect(()=> {
        setSubmit(false);
        setValidate(false);
        setLocation('')
    }, [studentType])
    
    // function to send a student request to the database
    const handleRegister = () => {
        setLoading(true);
        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value;
        let parentEmail = document.getElementById('parentemail').value;
   
        
        let requestObject = {
            studentName: name, 
            studentPhone: phone,
            parentName: null, 
            parentEmail: parentEmail, 
            parentPhone: null, 
            codeUsed: tutorName,
            location: location
        };

        isValidName(name)
        .then(res =>{
            if(res){
                newBootcampRequest(bootcampInfo._id, requestObject)
                .then(res => {
                    console.log(res); 
                    setLoading(false);
                    setSubmit(true)
                })
                .catch(function (err) {
                    console.log(err.response.data.status);
                    if(err.response.data.status === 500){
                        alert(err.response.data.message)
                    }
                    else {
                        initializeFlags(err.response.data.data, setErrors)
                    }
                    setLoading(false);
                });
            }
            else{
                let newErrors = errors.slice();
                newErrors[0] = "Please provide a first and last name";
                setErrors(newErrors);
                setLoading(false);
            }
        }).catch(err => console.log(err))
        
    }

    const handleClose = () => {
        setShowBootcamp(false);
        setTimeout(()=> {
            setValidate(false);
            setSubmit(false);
            setStudentType(null);
            setLocation('');
            setLoading(false);
            setTutorName(null);
        }, 500);
    }

    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }

    function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    

    const handleLink = () => {
        console.log(bootcampInfo._id)
        let link = `/checkout/course/${bootcampInfo._id}`;
  
        navigate(link)
    }

    const handleValidate = (e) => {

        setValidating(true);
        
        let validate = '_'
        if(document.getElementById('validate').value !== '') {
            validate = document.getElementById('validate').value;
            setTutorName(document.getElementById('validate').value);
        }
        validateCourseRequest(validate)
        .then(res => {
            console.log(res)
            if(res.data.valid)
            {
                setValidate(true);
            }
            else{
                let newError = errors.slice();
                newError[9] = 'Invalid Tutor'; 
                setErrors(newError)
                setTutorName(null);
            }

            setValidating(false)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='dialog'
        >
            <Modal.Header closeButton>
            {bootcampInfo.meetings && bootcampInfo.meetings[0].entryType === 'psatbootcamp' 
            ? <Modal.Title>Enroll in a PSAT Bootcamp</Modal.Title>
            : (bootcampInfo.meetings && bootcampInfo.meetings[0].entryType === 'actbootcamp' 
                ? <Modal.Title>Enroll in an ACT Bootcamp</Modal.Title>
                : <Modal.Title>Enroll in an SAT Bootcamp</Modal.Title>
              )

            }
            
            </Modal.Header>
            {submitted 
            ?(
                <><Modal.Body>
                <p style={{fontWeight:'500'}}>Your request has been submitted!</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Okay
                </Button>
                </Modal.Footer></>
            )
            :(
                <><Modal.Body>
                <Form id='requestForm'>
                    <Form.Group>
                        <p style={{marginBottom: '10px', fontSize: '18px'}}>Please select an option: </p>
                        <div>
                            <Form.Check 
                                type='radio'
                                name='studenttype'
                                value='new'
                                label={`Student`}
                                onChange={() => setStudentType('new')}
                            />
                            {/* <Form.Check 
                                type='radio'
                                name='studenttype'
                                value='current'
                                label={`Current Beck Student`}
                                onChange={() => setStudentType('current')}
                            /> */}
                        </div>
                    </Form.Group>
                    {studentType === 'current'?
                        <Form.Group style={{marginTop: '10px'}}>
                            <div onFocus={()=>clearError(9)} style={{position: 'relative'}}>
                                {errors[9]?.length > 0 ? <ErrorTooltip id='tutor' errorMsg={errors[9]}/> : null}
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>Please provide your tutor's name (first and last name): </p>
                                <div className='d-flex'>
                                    {validated
                                    ?
                                    <>
                                        <Form.Control id='validate' placeholder="Tutor Name" disabled/>
                                        <Button className = {styles.subButton} disabled>Submit</Button>
                                    </>
                                    :
                                    <>
                                        <Form.Control id='validate' placeholder="Tutor Name" required/>
                                        <Button className = {styles.subButton} onClick={(e) => handleValidate(e)}>Submit</Button>
                                    </>
                                    }
                                    
                                </div>
                            </div>
                        </Form.Group>
                    :
                        (studentType === 'new' ? 
                        <>
                            <p style={{fontSize: '18px'}}>Meetings:</p>
                            {isVertical ?
                                <>
                                {bootcampInfo.meetings.map((meeting, i) => (
                                    <p style={{textAlign: 'center', color: 'black'}} key={i}>
                                        <span style={{fontWeight: '500'}}>{`Day ${i+1}: `}</span>
                                        {`${new Date(meeting.start).toLocaleDateString()}, ${moment(meeting.start).format('h:mm A')} - 
                                        ${moment(meeting.end).format('h:mm A')}`}
                                    </p>
                                ))}
                                </>
                            : 
                                <>
                                <div className={`${styles.selectedDiagInput} d-flex justify-content-around`}>
                                <Table>
                                    <tbody>
                                        {bootcampInfo.meetings.map((meeting, i) => (
                                            <tr style={{borderBottom: 'solid 1px #dee2e6'}} key={i}>
                                                <td >
                                                    <p style={{color: 'black', fontSize: '16px', marginBottom: '0px', paddingLeft: '17px'}}>Day: {moment(meeting.start).format('dddd')}</p>
                                                </td>
                                                <td>
                                                    <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Date: {new Date(meeting.start).toLocaleDateString()}</p>
                                                </td>
                                                <td >
                                                    <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Time: {moment(meeting.start).format('h:mm A')} - {moment(meeting.end).format('h:mm A')}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </div>
                                </>
                            }
                            <p style={{fontSize: '18px', marginTop: '40px', textAlign: 'center'}}>(Clicking the enroll button below will take you to this bootcamp's checkout page.)</p>    
                        </>
                        :
                            null
                        )
                    }
                    {validated && studentType === 'current'?
                    <>
                        <p style={{fontSize: '18px'}}>Meetings:</p>
                        {isVertical ?
                        <>
                            {bootcampInfo.meetings.map((meeting, i) => (
                                <p style={{textAlign: 'center', color: 'black'}}>
                                    <span style={{fontWeight: '500'}}>{`Day ${i+1}: `}</span>
                                    {`${new Date(meeting.start).toLocaleDateString()}, ${moment(meeting.start).format('h:mm A')} - 
                                    ${moment(meeting.end).format('h:mm A')}`}
                                </p>
                            ))}
                        </>
                        : 
                        <>
                            
                            <div className={`${styles.selectedDiagInput} d-flex justify-content-around`}>
                            <Table>
                                <tbody>
                                    {bootcampInfo.meetings.map((meeting, i) => (
                                        <tr style={{borderBottom: 'solid 1px #dee2e6'}} key={i}>
                                            <td >
                                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px', paddingLeft: '17px'}}>Day: {moment(meeting.start).format('dddd')}</p>
                                            </td>
                                            <td>
                                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Date: {new Date(meeting.start).toLocaleDateString()}</p>
                                            </td>
                                            <td >
                                                <p style={{color: 'black', fontSize: '16px', marginBottom: '0px'}}>Time: {moment(meeting.start).format('h:mm A')} - {moment(meeting.end).format('h:mm A')}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </div>
                        </>
                        }
                    </>
                    :
                        null
                    }
                    {validated && studentType==='current' ? 
                        <>
                            <p style={{marginTop: '30px', marginBottom: '10px', fontSize: '18px'}}>Please fill out the form below: </p>
                            <Form.Group>
                            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                                {errors[0].length > 0 ? <ErrorTooltip id='name' errorMsg={errors[0]}/> : null}
                                <Form.Control  className = {``} id='name' placeholder="Student Name" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                                {errors[1].length > 0 ? <ErrorTooltip id='phone' errorMsg={errors[1]}/> : null}
                                <Form.Control className = {``} id='phone' placeholder="Student Phone Number" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(5)} style={{position: 'relative'}}>
                                {errors[5].length > 0 ? <ErrorTooltip id='parentemail' errorMsg={errors[5]}/> : null}
                                <Form.Control className = {``} id='parentemail' type='email' placeholder="Email*" required/>
                            </div>
                            <p style={{fontSize: '14px', marginTop: '3px'}}>*This is the email that will receive notifications related to this course.</p>
                            </Form.Group>
                            <Form.Group>
                                <div onFocus={()=>clearError(10)} style={{position: 'relative'}}>
                                    {errors[10].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[10]}/> : null}
                                    <p style={{marginBottom: '10px', fontSize: '18px'}}>How will the Student be attending?: </p>
                                    <div>
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='location'
                                            value={'Zoom'}
                                            label={`Zoom`}
                                            onChange={() => setLocation('Zoom')}
                                        />
                                        <Form.Check 
                                            inline
                                            type='radio'
                                            name='location'
                                            value={'In Person'}
                                            label={`In Person`}
                                            onChange={() => setLocation('In Person')}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </>
                    :   
                        null
                    }
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                    Cancel
                </Button>
                {validated && studentType==='current'? 
                    (loading ?
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            disabled
                            style={{minWidth: '83px'}}
                        >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button 
                            variant="primary" 
                            className={`${styles.subButton} register-diagnostic`}
                            type='submit' 
                            onClick={function () {handleRegister()}}
                        >
                            Register
                        </Button>
                    )
                :
                    (studentType==='new'?
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            onClick={function () {handleLink()}}
                        >
                            Enroll
                        </Button>
                    :
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            disabled
                        >
                            Register
                        </Button>
                    )
                    
                }
                </Modal.Footer></>
            )
            }
        </Modal>
    )
}

export default BootcampEventModal;